/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import Layout from '@components/layout';
import SEO from "@components/seo";
import { getOgImage } from "@utils/functions";
import { sanitize } from '@utils/functions';
import PostContent from '@components/post-content';

import { PAGE_TEMPLATE_NO_HEADER } from '../../constants/page';

/**
 * Single Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const Page = (props) => {
	const { pageContext } = props;
	const {
		title,
		contentRaw,
		content,
		seo,
		uri,
		template,
		pageMeta,
		reusableBlocks
	} = pageContext;
	// console.log('pageContext', props)

	let theme = (pageMeta && pageMeta.theme) ? pageMeta.theme : '';
	const openGraphImage = getOgImage(seo);
	const { templateName } = template ?? '';

	return (
		<Layout title={title} uri={uri} theme={theme} className="page" >
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			{
				(PAGE_TEMPLATE_NO_HEADER !== kebabCase(templateName)) && (
					<h1 dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
				)
			}
			<PostContent content={content} contentRaw={contentRaw} reusableBlocks={reusableBlocks} />
		</Layout>
	)
};

Page.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Page;
